import React from "react";
import { Column } from "../../common";
const EditContainer = ({
  title,
  text,
  returnTriage,
  triagePrompt,
  returnResponse,
}) => {
  function leftColumn() {
    return (
      <>
        <div className="lg:min-w-0 lg:flex-1">
          <div className="h-full">
            {title}
            {text}
          </div>
        </div>
      </>
    );
  }

  function rightColumn() {
    return (
      <>
        {returnTriage}
        {triagePrompt}
        {returnResponse}
      </>
    );
  }

  return <Column left={leftColumn()} right={rightColumn()} />;
};

export default EditContainer;
