import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import _ from "lodash";
import css from "../guides.scss";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import colours from "../../../data/colors.json";
import EditContainer from "../editContainer";

import { PageHeader, LinkButton } from "../../../common/index";

const Index = ({ location, navigate, search }) => {
  const guides = useSelector((state) => state.guides);
  const [selected_guide, update_selected_guide] = useState({
    title: "",
    createdAt: "",
    text: "",
    data: { id: 1, name: "soon" },
    data2: "",
    data3: "",
  });

  useEffect(() => {
    if (guides.length !== 0) {
      var find_guide = _.find(guides, { id: search.id });
      try {
        find_guide.data = JSON.parse(find_guide.data);
      } catch (error) {}
      update_selected_guide(find_guide);
    }
  }, [guides]);

  if (selected_guide === null) {
    return <div>Loading.....</div>;
  }

  function returnTriage() {
    // const triage = JSON.parse(selected_guide.data).name;

    try {
      switch (selected_guide.data.id) {
        case 1:
          return <div className="triage_box routine">Triage: Routine</div>;
        case 2:
          return <div className="triage_box soon">Triage: Soon</div>;
        case 3:
          return <div className="triage_box urgent">Triage: Urgent</div>;
        default:
          <div>no triage info</div>;
          break;
      }
    } catch (error) {
      return <div>no triage info</div>;
    }
  }

  function returnTitle() {
    return (
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {selected_guide.title}
        </h3>
      </div>
    );
  }

  function returnText() {
    return (
      <div
        className="html_text"
        dangerouslySetInnerHTML={{ __html: selected_guide.text }}
      ></div>
    );
  }

  function returnTriagePrompt() {
    return (
      <div
        className={"html_text_triage"}
        dangerouslySetInnerHTML={{ __html: selected_guide.data2 }}
      ></div>
    );
  }

  function returnResponse() {
    return (
      <div className="response_container">
        <div className="response_title">Response</div>
        <div className="relative" style={{ marginTop: -20 }}>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              type="button"
              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>
              <span>Copy to clipboard</span>
            </button>
          </div>
        </div>
        <div className="response-text"> {selected_guide.data3}</div>
      </div>
    );
  }

  return (
    <div>
      <PageHeader title={"Guide: " + selected_guide.title}>
        <LinkButton to={"/guides/edit?id=" + selected_guide.id} title="Edit" />
      </PageHeader>

      <EditContainer
        title={returnTitle()}
        text={returnText()}
        returnTriage={returnTriage()}
        triagePrompt={returnTriagePrompt()}
        returnResponse={returnResponse()}
      />
    </div>
  );
};

export default Index;
